<template>
    <div>
        <h4>Fastighetsskötsel</h4>
        <b-container>
            <b-row>
                <b-col cols="12">
                    <b-card>
                        <b-container>
                            <b-row>
                                <b-col cols="12">
                                    <a :href="imageUrl" target="_blank"><img :src="imageUrl" class="img-fluid thumbnail" alt="Hero Image"></a>
                                </b-col>
                            </b-row>
                        </b-container>
                    </b-card>
                </b-col>
                <b-col cols="12">
                    <b-card>
                        <b>Upload new image</b>
                        <b-form-group id="file-group">
                            <b-form-file v-model="file" type="file" accept="image/png, image/jpeg, image/gif" id="file" ref="file" required/>
                        </b-form-group>

                        <b-button class="default-button" v-on:click="submitFile()">Submit</b-button> <img v-show="uploading" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                    </b-card>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
    import config from 'config';
    import axios from "axios";

    export default {
        name: "MaintenancePage",
        data () {
            return {
                imageLocation: 2,
                file: '',
                imageUrl: '',
                key: 0,
                uploading: false,
            }
        },
        mounted() {
           this.getImageUrl();
        },
        computed: {
        },
        methods: {
            getImageUrl() {
                this.imageUrl = `${config.apiUrl}/HeroImages?location=${this.imageLocation}?key=${this.key}`;
                this.key += 1;
            },
            submitFile(){
                if (this.file === '') {
                    alert("Please select a picture.");
                    return;
                }
                this.uploading = true;

                let formData = new FormData();
                formData.append('img_file', this.file);
                formData.append('location', this.imageLocation);

                axios.post( `${config.apiUrl}/HeroImages/update`,
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('user')).token}`
                        }
                    }
                ).then(() => {
                    this.getImageUrl();
                    this.uploading = false;
                }).catch((e) => {
                    this.uploading = false;
                    if (e.response) {
                        console.log(e.response.status)
                        if (e.response.status === 403) {
                            this.$store.dispatch('alert/error', 'Session expired. Please login again.', {root: true});
                            this.$router.push('/login');
                        } else if (e.response.status === 502) {
                            this.$store.dispatch('alert/error', 'Server error.', {root: true});
                        } else if (e.response.data.message) {
                            this.$store.dispatch('alert/error', e.response.data.message, { root: true });
                        } else {
                            this.$store.dispatch('alert/error', e.response.data, { root: true });
                        }
                    }
                });
            },
        }
    }
</script>

<style scoped>
    .thumbnail {
        width: 100%;
        height: 200px;
        object-fit: cover;
    }
</style>
